const thirdPartyDomain = 'webbrowsertests.com'; // which server do third-party checks use?
const WIMB = global.WIMB;

WIMB.detect.cookies_third_party.trigger_set_cookie(
  '//' + thirdPartyDomain + '/detect/are-third-party-cookies-enabled-set-cookie'
);

export function checkThirdPartyEnabledInterval(cb, int = 50) {
  global.cookieCheckInterval = setInterval(function() {
    let isEnabled = WIMB.detect.cookies_third_party.enabled();
    if (isEnabled !== null) {
      cb(isEnabled);
      clearInterval(global.cookieCheckInterval);
    }
  }, int);
}
