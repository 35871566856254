const privacyTerm = `
<p><b>Ezbuy</b></p>
<hr />
<p>全運河國際股份有限公司對於個人資料隱私權聲明︰</p>
<p>
  使用本系統所輸入的資料，同意本公司暨其關係企業，以及受委託處理單位做以下目的的使用：
</p>
<ol>
  <li>個人、客戶管理和交易資料。</li>
  <li>產品和市場資料。</li>
  <li>上述資料的統計分析。</li>
  <li>產品促銷訊息寄送和推播。</li>
  <li>行銷和業務之目的使用。</li>
</ol>
<p>日後依「本公司 隱私權」方式處理如下：</p>
<ol>
  <li>系統上查詢、修改和刪除個人、客戶管理和交易資料。</li>
  <li>下載複製前項資料。</li>
  <li>退出並刪除系統本公司即停止蒐集、處理或利用該資料。</li>
  <li>請求刪除後，即無法使用本系統和提供相關服務。</li>
</ol>
<p>送出本項表單後，表示您本人同意本公司隱私權聲明中所述之內容。</p>
<p>
  本系統已取得：臺灣發明專利
  I629655和I655595，美國發明專利US10360552B2，的授權。
</p>
<p>相關資料存在「中華電信」雲端，受國際的個人資料保密法的保護。</p>
<!-- <p>系統得使用ID、大頭貼和暱稱方便團主和團友之間連結。</p>
<p>同意個人資料，按同意鍵進入。</p> -->
`;

export default privacyTerm;
