<template>
  <div>
    <div :class="['login-top', topHeaderClass]">
      <div class="esy-logo">
        <img :src="require('@/assets/img/logo.jpeg')" alt="" />
      </div>
    </div>
    <div class="main-content">
      <div class="content-block">
        <div class="container">
          <LoginContent
            :agreeTerm="agreeTerm"
            @update="updateAgreeTerm"
            @showTerm="showTerm"
          ></LoginContent>
        </div>
      </div>
      <div class="bottom-btn-block" @click="clickLoginBlock">
        <CompanyMemberLogin
          v-if="companyMemberLoginEnabled"
          :disabled="!agreeTerm"
          @toggle-company-member-login="toggleCompanyMemberLoginModal"
        ></CompanyMemberLogin>

        <FacebookBrowserLogin
          :disabled="!agreeTerm"
          v-if="isFacebook && isFacebookApp"
          @login="setAuth"
          @connected-error="showError"
        ></FacebookBrowserLogin>
        <FacebookLogin
          v-else-if="isFacebook"
          :disabled="!agreeTerm"
          @login="setAuth"
        ></FacebookLogin>
        <LineLiffLogin
          v-if="isLINE && isInLineClient && !isInLineSquareChat"
          :disabled="!agreeTerm"
          @login="setAuth"
        ></LineLiffLogin>
        <LineLogin
          v-else-if="isLINE"
          :disabled="!agreeTerm"
          @login="setAuth"
        ></LineLogin>
        <TelegramLogin
          v-if="isTelegram"
          :disabled="!agreeTerm"
          @login="callTelegramLogin"
        ></TelegramLogin>
      </div>

      <MessageAlert
        :show="showMessageModal"
        @close-modal="confirmMsg"
        :msgTitle="msgTitle"
        :msgContent="msgContent"
        :modal-size="modalSize"
      ></MessageAlert>

      <ModalCompanyMemberLogin
        :show="showCompanyMemberLoginModal"
        msgTitle="企業登入"
        :companyMemberLoginRes="companyMemberLoginRes"
        @close-modal="toggleCompanyMemberLoginModal"
        @login="callCompanyMemberLogin"
      ></ModalCompanyMemberLogin>
    </div>
  </div>
</template>

<script>
import LoginContent from '@/components/LoginContent';
import FacebookLogin from '@/components/OAuth/Facebook';
import LineLogin from '@/components/OAuth/Line';
import LineLiffLogin from '@/components/OAuth/Line/liff-login';
import CompanyMemberLogin from '@/components/CompanyMemberLogin';
import TelegramLogin from '@/components/OAuth/Telegram';
import FacebookBrowserLogin from '@/components/OAuth/Facebook/browser';
import MessageAlert from '@/components/Modals/ModalMessageAlert';
import ModalCompanyMemberLogin from '@/components/Modals/ModalCompanyMemberLogin';
import { redirectAfterAuth } from '@/utils/redirect-to';
import { mapActions } from 'vuex';
import { isEmpty } from 'lodash';
import { checkThirdPartyEnabledInterval } from '@/utils/checkCookie';
import AppPlatformMixin from '@/mixins/app-platform';
import PrivacyTerm from '@/utils/default-privacy-term';

var liff = window.liff;

export default {
  mixins: [AppPlatformMixin],
  components: {
    LoginContent,
    FacebookLogin,
    FacebookBrowserLogin,
    LineLogin,
    LineLiffLogin,
    TelegramLogin,
    MessageAlert,
    ModalCompanyMemberLogin,
    CompanyMemberLogin
  },
  data() {
    return {
      authInfo: {},
      agreeTerm: true, //同意條款

      //for message
      showMessageModal: false,
      showCompanyMemberLoginModal: false,
      companyMemberLoginRes: null,
      msgTitle: null,
      msgContent: null,
      helperImg: `<img src="${require('@/assets/img/facebook-login-helper.png')}" width="100%"/>`,

      companyMemberLoginEnabled:
        process.env.VUE_APP_COMPANY_MEMBER_LOGIN_ENABLED == 'true'
    };
  },
  computed: {
    userAgent() {
      return navigator.userAgent || navigator.vendor || window.opera;
    },
    isFacebookApp() {
      let ua = this.userAgent;
      return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
    },
    loginErrContent() {
      let msgContent = '第一次使用請使用瀏覽器登入授權';

      if (this.isFacebookApp) {
        msgContent = this.helperImg;
        msgContent += `<br/>
        ios教學:<br/>
        <iframe width="200" src="https://www.youtube.com/embed/vK6DGFm2JSc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <br/>
        android教學:<br/>
        <iframe width="200" src="https://www.youtube.com/embed/Pl8TXClO590" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `;
      }

      return msgContent;
    },
    modalSize() {
      let size = 'sm';

      if (this.isFacebookApp) {
        size = 'lg';
      }

      return size;
    },
    topHeaderClass() {
      return this.contentBodyClass;
    },
    isInLineClient() {
      if (liff) {
        return liff.isInClient() === true;
      }

      return false;
    },
    //line 社群 openchat 無法取得使用者資訊, liff.access_token 無效
    isInLineSquareChat() {
      return (
        this.isInLineClient && this.$liff.getContext().type === 'square_chat'
      );
    }
  },
  watch: {
    'authInfo.authResponse.accessToken'(val) {
      if (val) {
        this.callLogin();
      }
    }
  },
  methods: {
    ...mapActions('user', ['login', 'companyMemberLogin', 'getInfo']),
    setAuth(authInfo) {
      this.authInfo = authInfo;
    },
    callLogin() {
      let options = {
        code: this.authInfo.authResponse.accessToken,
        email: this.authInfo.profile.email
      };
      this.baseCallLogin(options);
    },
    //企業登入
    callCompanyMemberLogin(options) {
      this.companyMemberLogin(options)
        .then(() => {
          this.toggleCompanyMemberLoginModal();
          redirectAfterAuth();
        })
        .catch(err => {
          this.companyMemberLoginRes = err;
        });
    },
    callTelegramLogin(options) {
      this.baseCallLogin(options);
    },
    baseCallLogin(options) {
      if (this.agreeTerm === false) {
        this.showConfirmTerm();
        return;
      }
      this.login(options).then(async () => {
        await this.getInfo();
        redirectAfterAuth();
      });
    },
    confirmMsg() {
      this.reset();
    },
    reset() {
      this.showMessageModal = false;
      this.msgTitle = null;
      this.msgContent = null;
    },
    showError() {
      this.showMessageModal = true;
      this.msgTitle = '授權錯誤';
      this.msgContent = this.loginErrContent;
    },
    showConfirmTerm() {
      this.showMessageModal = true;
      this.msgTitle = '訊息';
      this.msgContent = '請閱讀並同意本平台隱私權條款';
    },
    showCookieMsg() {
      this.showMessageModal = true;
      this.msgTitle = '提醒';
      this.msgContent = `請啟用您瀏覽器的</br>第三方Cookie</br>並允許跨網站追蹤</br>以正常使用本服務
          <iframe width="200" src="https://www.youtube.com/embed/_2ISt_N9d2s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `;
    },
    updateAgreeTerm(val) {
      this.agreeTerm = val;
    },
    clickLoginBlock() {
      if (this.agreeTerm === false) {
        this.showConfirmTerm();
      }

      return;
    },
    showTerm() {
      this.showMessageModal = true;
      this.msgTitle = '隱私權政策';
      this.msgContent = PrivacyTerm;
    },
    toggleCompanyMemberLoginModal() {
      this.showCompanyMemberLoginModal = !this.showCompanyMemberLoginModal;
    }
  },
  mounted() {
    checkThirdPartyEnabledInterval(enabled => {
      if (enabled === false && this.isTelegram) {
        this.showCookieMsg();
      }
    });
  }
};
</script>
