<template>
  <!-- 使用Telegram版本 -->
  <!-- Callback mode -->
  <vue-telegram-login
    mode="callback"
    :telegram-login="telegramBotUsername"
    @callback="yourCallbackFunction"
  >
    使用Telegram版本
  </vue-telegram-login>
</template>

<script>
import { vueTelegramLogin } from 'vue-telegram-login';

export default {
  name: 'telegram-login',
  props: {
    telegramBotUsername: {
      required: false,
      default() {
        return process.env.VUE_APP_TELEGRAM_USERNAME;
      }
    }
  },
  components: { vueTelegramLogin },
  methods: {
    yourCallbackFunction(user) {
      // gets user as an input
      // id, first_name, last_name, username,
      // photo_url, auth_date and hash
      this.$emit('login', user);
    }
  }
};
</script>
