export default {
  props: {
    appId: {
      required: false,
      type: String,
      default() {
        return process.env.VUE_APP_FB_APP_ID;
      }
    },
    appVersion: {
      required: false,
      type: String,
      default() {
        return process.env.VUE_APP_FB_APP_VERSION;
      }
    },
    appLanguage: {
      required: false,
      type: String,
      default() {
        return process.env.appLanguage;
      }
    },
    queryScope: {
      required: false,
      type: String,
      default() {
        return 'name,id,email';
      }
    }
  },
  data() {
    return {
      authResponse: {},
      profile: {}
    };
  },
  computed: {
    authInfo() {
      return {
        authResponse: this.authResponse,
        profile: this.profile
      };
    }
  },
  methods: {
    login(res) {
      this.authResponse = res.authResponse;
      this.getProfile().then(res => {
        this.profile = res;
        this.$emit('login', this.authInfo);
      });
    },
    logout() {
      //
    },
    getProfile() {
      return new Promise((resolve, reject) => {
        window.FB.api(`/me?fields=${this.queryScope}`, res => {
          resolve(res);
        });
      });
    }
  }
};
