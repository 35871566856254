<template>
  <a class="login-btn fb" @click.prevent="loginWithFbBrowser">
    使用Facebook版本
  </a>
</template>

<script>
import FacebookLoginMixin from '@/mixins/facebook';
import { get } from 'lodash';

export default {
  mixins: [FacebookLoginMixin],
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  methods: {
    loginWithFbBrowser() {
      if (this.disabled === true) {
        return;
      }
      window.FB.getLoginStatus(response => {
        if (response.status !== 'connected') {
          this.$emit('connected-error');
        } else {
          this.handleResponse(response);
        }
      });
    },
    handleResponse(response) {
      this.authResponse = response.authResponse;
      this.getProfile().then(res => {
        this.profile = res;
        this.$emit('login', this.authInfo);
      });
    }
  },
  created() {
    const vm = this;
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: vm.appId,
        cookie: true,
        xfbml: true,
        version: vm.appVersion
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/${vm.appLanguage}/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }
};
</script>
