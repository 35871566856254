<template>
  <div :class="['login-content', contentBodyClass]">
    <!-- <FacebookLoginContent v-if="isFacebook"></FacebookLoginContent>
    <LineLoginContent v-if="isLINE"></LineLoginContent>
    <TelegramLoginContent v-if="isTelegram"></TelegramLoginContent> -->
    <div v-html="login_page_content"></div>
    <div class="check-block">
      <input type="checkbox" v-model="isAgreeTerm" />
      已詳閱並同意本平台
      <a @click.prevent="showTerm">隱私權政策</a>
      不同意請直接離開本系統
    </div>
  </div>
</template>

<script>
// import FacebookLoginContent from '@/components/LoginContent/Facebook';
// import LineLoginContent from '@/components/LoginContent/Line';
// import TelegramLoginContent from '@/components/LoginContent/Telegram';
import AppPlatformMixin from '@/mixins/app-platform';
import { getLoginPages } from '@/api/loginPage';

export default {
  mixins: [AppPlatformMixin],
  components: {
    // FacebookLoginContent,
    // LineLoginContent,
    // TelegramLoginContent
  },
  props: {
    agreeTerm: {
      required: false,
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      login_page_content: ''
    };
  },
  computed: {
    isAgreeTerm: {
      get() {
        return this.agreeTerm;
      },
      set(val) {
        this.$emit('update', val);
      }
    }
  },
  created() {
    getLoginPages().then(data => {
      data.forEach((ele, index) => {
        if (this.isFacebook && ele.type == 2) {
          this.login_page_content = ele.content;
        } else if (this.isLINE && ele.type == 1) {
          this.login_page_content = ele.content;
        } else if (this.isTelegram && ele.type == 3) {
          this.login_page_content = ele.content;
        }
      });
    });
  },
  methods: {
    showTerm() {
      this.$emit('showTerm');
    }
  }
};
</script>
