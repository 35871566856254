import request from '@/utils/request';

export function getLoginPages() {
  return request({
    url: 'login_pages',
    method: 'get'
  });
}

export default {
  getLoginPages
};
