<template>
  <v-facebook-login-scope
    :app-id="appId"
    :version="appVersion"
    @login="login"
    @logout="logout"
  >
    <a
      class="login-btn fb"
      slot-scope="scope"
      @click.prevent="checkLogin(scope)"
    >
      使用Facebook版本
    </a>
  </v-facebook-login-scope>
</template>

<script>
import { VFBLoginScope as VFacebookLoginScope } from 'vue-facebook-login-component';
import FacebookLoginMixin from '@/mixins/facebook';

export default {
  mixins: [FacebookLoginMixin],
  components: {
    VFacebookLoginScope
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  methods: {
    checkLogin(scope) {
      if (this.disabled === true) {
        return;
      }

      scope.login();
    }
  }
};
</script>
