<template>
  <a
    class="login-btn company-member-login"
    @click.prevent="toggleCompanyMemberLoginModal"
  >
    企業會員登入
  </a>
</template>

<script>
export default {
  name: 'company-member-login-button',
  data() {
    return {
      showCompanyMemberLoginModal: false
    };
  },
  methods: {
    toggleCompanyMemberLoginModal() {
      this.$emit('toggle-company-member-login');
    }
  }
};
</script>
