<template>
  <a class="login-btn line" @click.prevent="login">使用LINE版本</a>
</template>

<script>
import queryString from 'querystring';
import axios from 'axios';
import OAuthRequest from './Entities/OAuthRequest';
import linq from 'linq';
import OAuthResult from './Entities/OAuthResult';

export default {
  name: 'line-login-button',
  props: {
    clientId: {
      type: String,
      require: true,
      default() {
        return process.env.VUE_APP_LINE_APP_ID;
      }
    },
    clientSecret: {
      type: String,
      require: true,
      default() {
        return process.env.VUE_APP_LINE_APP_SECRET;
      }
    },
    callbackUri: {
      type: String,
      require: true,
      default() {
        return process.env.VUE_APP_LINE_APP_CALLBACK;
      }
    },
    disabled: {
      required: false,
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      error: '',
      accessToken: ''
    };
  },
  computed: {
    authInfo() {
      return {
        authResponse: {
          accessToken: this.accessToken
        },
        profile: {
          email: ''
        }
      };
    }
  },
  async created() {
    const { code } = queryString.parse(window.location.search.replace('?', ''));

    if (!code) return;

    const result = await this.getToken(code);

    this.accessToken = result.token;

    this.$emit('login', this.authInfo);
  },
  methods: {
    login() {
      if (this.disabled === false) {
        const url = 'https://access.line.me/oauth2/v2.1/authorize';

        let params = {
          response_type: 'code',
          client_id: this.clientId,
          redirect_uri: this.callbackUri,
          state: Math.random()
            .toString(32)
            .substring(2),
          scope: 'openid profile email'
        };

        window.location.href = `${url}?${queryString.stringify(params)}`;
      }
    },
    async getToken(code) {
      const result = new OAuthRequest({
        code: code,
        clientId: this.clientId,
        clientSecret: this.clientSecret,
        redirectUri: this.callbackUri
      });

      const params = new URLSearchParams();

      linq
        .from(result.getPostable())
        .select(x => params.append(x.key, x.value))
        .toArray();

      const { data } = await axios.post(
        'https://api.line.me/oauth2/v2.1/token',
        params
      );

      return new OAuthResult(data);
    }
  }
};
</script>
