<template>
  <a class="login-btn line" @click.prevent="login">
    使用LINE版本
  </a>
</template>

<script>
export default {
  name: 'line-liff-login-button',
  props: {
    clientId: {
      type: String,
      require: true,
      default() {
        return process.env.VUE_APP_LINE_APP_ID;
      }
    },
    clientSecret: {
      type: String,
      require: true,
      default() {
        return process.env.VUE_APP_LINE_APP_SECRET;
      }
    },
    callbackUri: {
      type: String,
      require: true,
      default() {
        return process.env.VUE_APP_LINE_APP_CALLBACK;
      }
    },
    disabled: {
      required: false,
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      error: '',
      accessToken: '',
      liffStatus: {}
    };
  },
  computed: {
    authInfo() {
      return {
        authResponse: {
          accessToken: this.accessToken
        },
        profile: {
          email: ''
        }
      };
    }
  },
  async mounted() {
    if (this.$liff.isInClient()) {
      this.liffAccessTokenLogin();
    }
  },
  methods: {
    login() {
      if (this.disabled === false) {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login({ redirectUri: this.callbackUri });
        } else {
          this.liffAccessTokenLogin();
        }
      }
    },
    liffAccessTokenLogin() {
      if (this.$liff.isLoggedIn()) {
        this.accessToken = this.$liff.getAccessToken();
        this.$emit('login', this.authInfo);
      }
    }
  }
};
</script>
