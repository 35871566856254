<template>
  <div
    ref="company-members-login-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-helper" :class="`modal-${modalSize}`">
      <div class="modal-content">
        <div class="modal-title">{{ msgTitle }}</div>
        <div class="modal-body">
          <div class="row">
            <form>
              <div class="form-group">
                <div class="col-sm-12">
                  <input
                    type="text"
                    class="form-control input-cmlogin"
                    placeholder="帳號"
                    v-model="account"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input
                    type="password"
                    class="form-control input-cmlogin"
                    placeholder="密碼"
                    v-model="password"
                    required
                  />
                  <div class="invalid-feedback" v-if="errorMsg">
                    <small>
                      輸入錯誤，忘記密碼或有任何疑問
                      <br />
                      請聯繫易集買官方LINE客服
                    </small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button
                    type="submit"
                    class="btn btn-cmlogin"
                    @click.prevent="clickLogin"
                  >
                    登入
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    msgTitle: {
      type: String,
      required: false,
      default() {
        return '企業登入';
      }
    },
    modalSize: {
      type: String,
      required: false,
      default() {
        return 'xs';
      }
    },
    companyMemberLoginRes: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      account: null,
      password: null,
      errorMsg: null
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue, oldValue) {
        $(this.$refs['company-members-login-modal']).modal('toggle');
      }
    },
    companyMemberLoginRes(newVal, oldVal) {
      if (newVal) {
        if (newVal.success === false && newVal.message) {
          this.errorMsg = newVal.message;
          alert(this.errorMsg);
        } else {
          this.errorMsg = null;
        }
      }
    }
  },
  methods: {
    confirm() {
      this.$emit('close-modal');
    },
    clickLogin() {
      this.errorMsg = null;

      if (!(this.account && this.password)) {
        return;
      }

      this.$emit('login', {
        account: this.account,
        password: this.password
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-title {
  color: #fff;
}

//企業會員登入按鈕
.modal-content {
  background: linear-gradient(to bottom right, #a2c305, #3cc9e1);
  color: #fff;
  margin-bottom: 5px;
}
//登入按鈕
.btn-cmlogin {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 50%;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  border-radius: 25px;
  background-color: transparent;
  border-color: #fff;
  margin: 10px auto;
}

.btn-cmlogin:hover {
  opacity: 0.8;
  background-color: #fcc286;
}

//輸入框
.input-cmlogin {
  color: #fff;
  margin-bottom: 5px;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #fff;

  //disable form-control
  border-radius: 0;
  box-shadow: 0 0 0 0;
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
.input-cmlogin::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}

/* Internet Explorer 10-11 */
.input-cmlogin:-ms-input-placeholder {
  color: #fff;
}

/* Microsoft Edge */
.input-cmlogin::-ms-input-placeholder {
  color: #fff;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
